"use client";
import { H } from "@highlight-run/next/client";
import { useEffect } from "react";

// Error components must be Client Components

/**
 * An error UI boundary for a route segment.
 * Useful for catching unexpected errors that occur in Server Components
 * and Client Components and displaying a fallback UI.
 * @see https://nextjs.org/docs/app/api-reference/file-conventions/error
 */
export default function Error({
  error,
  reset,
}: {
  // During production, the Error object forwarded to the client only includes a generic message and digest property.
  error: Error & { digest?: string };
  reset: () => void;
}) {
  // Log the error to the console (and Highlight).
  // We use useEffect to ensure that this only runs once. In development, this will run on every render.
  useEffect(() => {
    if (error) {
      H.consumeError(error, undefined);
    }
  }, [error]);

  return (
    <div>
      <h2>Something went wrong!</h2>
      <button
        onClick={
          // Attempt to recover by trying to re-render the segment
          () => reset()
        }
      >
        Try again
      </button>
    </div>
  );
}
